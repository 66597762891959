/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AzureLoginRequestDTO } from '../models/AzureLoginRequestDTO';
import type { LoginUrlResponseDTO } from '../models/LoginUrlResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AzureControllerService {

    /**
     * Create a login url
     * @param requestBody
     * @param language
     * @returns LoginUrlResponseDTO Response contains url to login page
     * @throws ApiError
     */
    public static initiate(
        requestBody: AzureLoginRequestDTO,
        language?: string,
    ): CancelablePromise<LoginUrlResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/azure/login',
            query: {
                'language': language,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Handle return from Azure, trade 'code' for cookie and redirect user.
     * @param code
     * @returns void
     * @throws ApiError
     */
    public static finalize(
        code?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/azure/oidc/finalize',
            query: {
                'code': code,
            },
            errors: {
                302: `Redirect user to expected target URL or error page`,
            },
        });
    }

}