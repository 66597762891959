/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OutagesResponse } from '../models/OutagesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OutagesControllerService {

    /**
     * Get outages for organization
     * @param tscId
     * @param xAuthScopeId
     * @returns OutagesResponse OK
     * @throws ApiError
     */
    public static getOutages(
        tscId: string,
        xAuthScopeId: any,
    ): CancelablePromise<OutagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/outages',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
            },
            query: {
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

}