/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NumberMigrationInfoUI } from '../models/NumberMigrationInfoUI';
import type { PortInRequestBody } from '../models/PortInRequestBody';
import type { TransferRequestBody } from '../models/TransferRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NumberMigrationControllerService {

    /**
     * Resend a failed port in order
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param msisdn
     * @param dueDate
     * @returns string Successful request
     * @throws ApiError
     */
    public static resendPortInOrder(
        scopeId: string,
        msisdn: string,
        dueDate?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/scope/{scopeId}/subscriptions/{msisdn}/resend',
            path: {
                'scopeId': scopeId,
                'msisdn': msisdn,
            },
            query: {
                'dueDate': dueDate,
            },
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Place a transfer order to the order processor
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @param lang
     * @returns string Successful request
     * @throws ApiError
     */
    public static transfer(
        scopeId: string,
        c2Buid: string,
        requestBody: TransferRequestBody,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/transfer',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Place a port in order to the order processor
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @param lang
     * @returns string Successful request
     * @throws ApiError
     */
    public static portIn(
        scopeId: string,
        c2Buid: string,
        requestBody: PortInRequestBody,
        lang: 'SV' | 'EN' = 'SV',
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/portin',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Pre check that returns information regarding potential port in or transfer
     * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
     * @param scopeId
     * @param c2Buid
     * @param msisdn A phone number starting with 46xxxx
     * @returns NumberMigrationInfoUI Successful request.
     * @throws ApiError
     */
    public static getNumberMigrationInfoFromSubscription(
        scopeId: string,
        c2Buid: string,
        msisdn: string,
    ): CancelablePromise<NumberMigrationInfoUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/subscriptions/{c2buid}/precheck/msisdn/{msisdn}',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
                'msisdn': msisdn,
            },
            errors: {
                403: `Unauthorized request.`,
                500: `Internal server error.`,
            },
        });
    }

}