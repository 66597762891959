/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequestDTO } from '../models/LoginRequestDTO';
import type { LoginUrlResponseDTO } from '../models/LoginUrlResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LoginControllerService {

    /**
     * Create a login url to CIAM with an optional target URL
     * @param requestBody
     * @param language
     * @param prompt Overrides prompt param in redirect url. default 'consent'
     * @param fpid
     * @param consentedCookieGroups
     * @returns LoginUrlResponseDTO Response contains url to login page
     * @throws ApiError
     */
    public static initiateLoginUrl(
        requestBody: LoginRequestDTO,
        language?: string,
        prompt?: string,
        fpid?: string,
        consentedCookieGroups?: string,
    ): CancelablePromise<LoginUrlResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/loginurl',
            cookies: {
                'FPID': fpid,
                'consentedCookieGroups': consentedCookieGroups,
            },
            query: {
                'language': language,
                'prompt': prompt,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Initiate login to CIAM with an optional target URL
     * @param language
     * @param prompt Overrides prompt param in redirect url. default 'consent'
     * @param fpid
     * @param consentedCookieGroups
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public static initiateLogin(
        language?: string,
        prompt?: string,
        fpid?: string,
        consentedCookieGroups?: string,
        formData?: LoginRequestDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login',
            cookies: {
                'FPID': fpid,
                'consentedCookieGroups': consentedCookieGroups,
            },
            query: {
                'language': language,
                'prompt': prompt,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                302: `Redirect user to login page`,
            },
        });
    }

    /**
     * Handle return from CIAM, trade 'code' for cookie and redirect user. If 'error' query param, redirect to an error page.
     * @param state
     * @param code
     * @param error
     * @param errorDescription
     * @returns void
     * @throws ApiError
     */
    public static handleReturnFromCiam1(
        state?: string,
        code?: string,
        error?: string,
        errorDescription?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oidc/ciam/login',
            query: {
                'state': state,
                'code': code,
                'error': error,
                'error_description': errorDescription,
            },
            errors: {
                302: `Redirect user to expected target URL or error page`,
            },
        });
    }

}