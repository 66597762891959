/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IccValidationUI } from '../models/IccValidationUI';
import type { OrderEsimRequest } from '../models/OrderEsimRequest';
import type { OrderSimcardRequest } from '../models/OrderSimcardRequest';
import type { PinPukUI } from '../models/PinPukUI';
import type { QRCodeLinkUI } from '../models/QRCodeLinkUI';
import type { SendQRCodeForNewSubscriptionRequest } from '../models/SendQRCodeForNewSubscriptionRequest';
import type { SendQRCodeRequest } from '../models/SendQRCodeRequest';
import type { SetSimcardStatusRequest } from '../models/SetSimcardStatusRequest';
import type { SimcardStatusUI } from '../models/SimcardStatusUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileSimcardsControllerService {

    /**
     * Returns SIM-card traffic status for given C2BUID
     * @param scopeId
     * @param c2Buid
     * @returns SimcardStatusUI Success
     * @throws ApiError
     */
    public static getStatusBasedOnC2BCache(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<SimcardStatusUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}/status',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Change the SIM-card status connected to given C2BUID. Use status BLOCKED_THEFT or OPEN
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static changeStatus(
        scopeId: string,
        c2Buid: string,
        requestBody: SetSimcardStatusRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}/status',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Orders new SIM-card for given C2BUID
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static orderSimcard(
        scopeId: string,
        c2Buid: string,
        requestBody: OrderSimcardRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a link to a QR-code for ESIM
     * @param scopeId
     * @param c2Buid
     * @returns QRCodeLinkUI Success
     * @throws ApiError
     */
    public static getQrCodeLink(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<QRCodeLinkUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}/qrcode',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                400: `Bad Request`,
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Request QR-code for ESIM to be sent to provided Email
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static sendQrCodeEmail(
        scopeId: string,
        c2Buid: string,
        requestBody: SendQRCodeRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}/qrcode',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Orders new E-SIM for given C2BUID. If Email is provided, a QR-code will be sent and a link to the QR-code will be returned. If Email is empty or null, a link to the QR-code will be returned
     * @param scopeId
     * @param c2Buid
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static orderEsim(
        scopeId: string,
        c2Buid: string,
        requestBody: OrderEsimRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}/esim',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a link to a QR-code for ESIM for a new subscription
     * @param scopeId
     * @param requestBody
     * @returns QRCodeLinkUI Success
     * @throws ApiError
     */
    public static getQrCodeLinkForNewSubscription(
        scopeId: string,
        requestBody: SendQRCodeForNewSubscriptionRequest,
    ): CancelablePromise<QRCodeLinkUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{scopeId}/mobile-simcards/newsubscription/qrcodelink',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Request QR-code for ESIM for new subscription to be sent to provided Email
     * @param scopeId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static sendQrCodeEmailForNewSubscription(
        scopeId: string,
        requestBody: SendQRCodeForNewSubscriptionRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{scopeId}/mobile-simcards/newsubscription/qrcode',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns PIN and PUK for given C2BUID
     * @param scopeId
     * @param c2Buid
     * @returns PinPukUI Success
     * @throws ApiError
     */
    public static getPukBasedOnC2BCache(
        scopeId: string,
        c2Buid: string,
    ): CancelablePromise<PinPukUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{scopeId}/mobile-simcards/{c2buid}/puk',
            path: {
                'scopeId': scopeId,
                'c2buid': c2Buid,
            },
            errors: {
                403: `Access Denied`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate ICC number
     * @param scopeId
     * @param icc
     * @returns IccValidationUI Successful request
     * @throws ApiError
     */
    public static validateIcc(
        scopeId: string,
        icc: string,
    ): CancelablePromise<IccValidationUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{scopeId}/mobile-simcards/validate/{icc}',
            path: {
                'scopeId': scopeId,
                'icc': icc,
            },
            errors: {
                400: `Bad ICC format. Response will contain an errorCode and errorMessage.`,
                500: `Internal server error`,
            },
        });
    }

}