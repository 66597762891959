/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organisation } from '../models/Organisation';
import type { OrganisationRequest } from '../models/OrganisationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TawControllerService {

    /**
     * @param scopeId
     * @returns Organisation OK
     * @throws ApiError
     */
    public static getSettings(
        scopeId: string,
    ): CancelablePromise<Array<Organisation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/taw/organisation/settings/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
        });
    }

    /**
     * @param scopeId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setSettings(
        scopeId: string,
        requestBody: OrganisationRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/taw/organisation/settings/{scopeId}',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}