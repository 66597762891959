/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChooseContactDTO } from '../models/ChooseContactDTO';
import type { ContactInformationDTO } from '../models/ContactInformationDTO';
import type { RoleInformationDTO } from '../models/RoleInformationDTO';
import type { UpdateContactInformationDTO } from '../models/UpdateContactInformationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactInformationControllerService {

    /**
     * Update contact information for role
     * @param scopeId
     * @param tscid
     * @param rolePartyId
     * @param requestBody
     * @returns ContactInformationDTO OK
     * @throws ApiError
     */
    public static updateContactInformationForRole(
        scopeId: string,
        tscid: string,
        rolePartyId: string,
        requestBody: UpdateContactInformationDTO,
    ): CancelablePromise<ContactInformationDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/public/scope/{scopeId}/{tscid}/contactinfo/{rolePartyId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'rolePartyId': rolePartyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Choose existing contact
     * @param scopeId
     * @param tscid
     * @param c2BUid
     * @param partyId
     * @param rolePartyId
     * @param requestBody
     * @returns ContactInformationDTO OK
     * @throws ApiError
     */
    public static chooseContact(
        scopeId: string,
        tscid: string,
        c2BUid: string,
        partyId: string,
        rolePartyId: string,
        requestBody: ChooseContactDTO,
    ): CancelablePromise<ContactInformationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public/scope/{scopeId}/{tscid}/contactinfo/{c2bUid}/{partyId}/{rolePartyId}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
                'c2bUid': c2BUid,
                'partyId': partyId,
                'rolePartyId': rolePartyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get list with all contacts
     * @param scopeId
     * @param tscid
     * @returns RoleInformationDTO OK
     * @throws ApiError
     */
    public static getAllRoleContactInformation(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<RoleInformationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/{tscid}/contactinfo',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * Get contact information for roles on a subscription
     * @param scopeId
     * @param c2BUid
     * @param tscid
     * @param roleTypes
     * @returns ContactInformationDTO OK
     * @throws ApiError
     */
    public static getRoleContactInformation(
        scopeId: string,
        c2BUid: string,
        tscid: string,
        roleTypes: Array<string>,
    ): CancelablePromise<Array<ContactInformationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/scope/{scopeId}/{tscid}/contactinfo/{c2bUid}',
            path: {
                'scopeId': scopeId,
                'c2bUid': c2BUid,
                'tscid': tscid,
            },
            query: {
                'roleTypes': roleTypes,
            },
        });
    }

}