/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AfterMarketOrder } from '../models/AfterMarketOrder';
import type { TeliaNowOrderResponse } from '../models/TeliaNowOrderResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AftermarketControllerService {

    /**
     * Send order to TeliaNow
     * Send aftermarket order to TeliaNOW
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns TeliaNowOrderResponse Successful request
     * @throws ApiError
     */
    public static aftermarket(
        scopeId: string,
        tscid: string,
        requestBody: AfterMarketOrder,
    ): CancelablePromise<TeliaNowOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{scopeId}/aftermarket/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}