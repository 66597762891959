/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MduAgreementNumber } from '../models/MduAgreementNumber';
import type { MoveOrder } from '../models/MoveOrder';
import type { ProptechAddress } from '../models/ProptechAddress';
import type { TeliaNowOrderResponse } from '../models/TeliaNowOrderResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MoveControllerService {

    /**
     * Move
     * Move addresses etc.
     * @param scopeId
     * @param tscid
     * @param requestBody
     * @returns TeliaNowOrderResponse Successful request
     * @throws ApiError
     */
    public static move(
        scopeId: string,
        tscid: string,
        requestBody: MoveOrder,
    ): CancelablePromise<TeliaNowOrderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{scopeId}/move/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * MDU Agreements
     * Get MDU agreements from tscid
     * @param scopeId
     * @param tscid
     * @returns MduAgreementNumber Successful request
     * @throws ApiError
     */
    public static getMduAgreements(
        scopeId: string,
        tscid: string,
    ): CancelablePromise<Array<MduAgreementNumber>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/mduagreements/{tscid}',
            path: {
                'scopeId': scopeId,
                'tscid': tscid,
            },
        });
    }

    /**
     * MDU Agreement
     * Get MDU agreement from addressPointId
     * @param scopeId
     * @param addressPointId
     * @returns MduAgreementNumber Successful request
     * @throws ApiError
     */
    public static getMduAgreement(
        scopeId: string,
        addressPointId: string,
    ): CancelablePromise<MduAgreementNumber> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/mduagreement/{addressPointId}',
            path: {
                'scopeId': scopeId,
                'addressPointId': addressPointId,
            },
        });
    }

    /**
     * Addresses
     * Get addresses
     * @param scopeId
     * @param agreementNumber
     * @returns ProptechAddress Successful request
     * @throws ApiError
     */
    public static getAddresses(
        scopeId: string,
        agreementNumber: string,
    ): CancelablePromise<Array<ProptechAddress>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/addresses/{agreementNumber}',
            path: {
                'scopeId': scopeId,
                'agreementNumber': agreementNumber,
            },
        });
    }

}