/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FileDownloadControllerService {

    /**
     * Download file
     * Download docx file template for secure connect
     * @param scopeId
     * @returns string OK
     * @throws ApiError
     */
    public static downloadSecureConnectOrderFile(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/file/download/secure-connect/order',
            path: {
                'scopeId': scopeId,
            },
            headers: {
                Accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            },
        });
    }

    /**
     * Download file
     * Download PDF instruction file
     * @param scopeId
     * @returns string OK
     * @throws ApiError
     */
    public static downloadSecureConnectInstructionFile(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/file/download/secure-connect/instruction',
            path: {
                'scopeId': scopeId,
            },
            headers: {
                Accept: "application/pdf",
            },
        });
    }

    /**
     * Download file
     * Download PDF instruction file
     * @param scopeId
     * @returns string OK
     * @throws ApiError
     */
    public static downloadProductFile(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/file/download/property-management-internet/product',
            path: {
                'scopeId': scopeId,
            },
            headers: {
                Accept: "application/pdf",
            },
        });
    }

    /**
     * Download file
     * Download xlsx address template file
     * @param scopeId
     * @returns string OK
     * @throws ApiError
     */
    public static downloadIotTemplateFile(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/file/download/iot-template',
            path: {
                'scopeId': scopeId,
            },
            headers: {
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
        });
    }

    /**
     * Download file
     * Download address excel template
     * @param scopeId
     * @returns string Successful request
     * @throws ApiError
     */
    public static downloadAddressFile(
        scopeId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{scopeId}/file/download/addresses',
            path: {
                'scopeId': scopeId,
            },
            headers: {
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
        });
    }

}