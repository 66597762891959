/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedSubscriptionsUI } from '../models/PaginatedSubscriptionsUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorporateListPaginationExportService {

    /**
     * Returns all subscriptions for given productCategory in a .csv file in a raw format
     * @param scopeId Customer identification number
     * @param requestBody
     * @returns PaginatedSubscriptionsUI Success
     * @throws ApiError
     */
    public static streamPaginatedCsv(
        scopeId: string,
        requestBody: string,
    ): CancelablePromise<PaginatedSubscriptionsUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/rawcsv',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns all subscriptions for given productCategory in a .xlsx file
     * @param scopeId Customer identification number
     * @param requestBody
     * @returns PaginatedSubscriptionsUI Success
     * @throws ApiError
     */
    public static streamPaginatedExcel(
        scopeId: string,
        requestBody: string,
    ): CancelablePromise<PaginatedSubscriptionsUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/excel',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

    /**
     * Returns all subscriptions for given productCategory in a .csv file
     * @param scopeId Customer identification number
     * @param requestBody
     * @returns PaginatedSubscriptionsUI Success
     * @throws ApiError
     */
    public static streamPaginatedPrettyCsv(
        scopeId: string,
        requestBody: string,
    ): CancelablePromise<PaginatedSubscriptionsUI> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/csv',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `ACCESS_DENIED`,
            },
        });
    }

}