/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NumberMigrationInfoUI } from "../models/NumberMigrationInfoUI";
import type { OrgSignatoryValidationRequestUI } from "../models/OrgSignatoryValidationRequestUI";
import type { OrgSignatoryValidationUI } from "../models/OrgSignatoryValidationUI";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NumberMigrationControllerService {
  /**
   * Validate if SSN is signatory of organization
   * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
   * @param scopeId
   * @param requestBody
   * @returns OrgSignatoryValidationUI Successful request.
   * @throws ApiError
   */
  public static validateOrganizationSignatory(
    scopeId: string,
    requestBody: OrgSignatoryValidationRequestUI
  ): CancelablePromise<OrgSignatoryValidationUI> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/public/scope/{scopeId}/port-in/validate-organization-signatory",
      path: {
        scopeId: scopeId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized request.`,
        500: `Internal server error.`,
      },
    });
  }

  /**
   * From the manage page: Get info about a msisdn with regards to portin
   * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
   * @param scopeId
   * @param c2Buid
   * @param msisdn
   * @returns NumberMigrationInfoUI Successful request.
   * @throws ApiError
   */
  public static getNumberMigrationInfoFromSubscription(
    scopeId: string,
    c2Buid: string,
    msisdn: string
  ): CancelablePromise<NumberMigrationInfoUI> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/public/scope/{scopeId}/number-migration/subscription/{c2buid}/msisdn/{msisdn}",
      path: {
        scopeId: scopeId,
        c2buid: c2Buid,
        msisdn: msisdn,
      },
      errors: {
        403: `Unauthorized request.`,
        500: `Internal server error.`,
      },
    });
  }

  /**
   * Validate ownership of msisdn against user input ssn or orgno
   * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
   * @param scopeId
   * @param msisdn
   * @param organisationNumber
   * @param swedishPersonNumber
   * @returns boolean Successful request.
   * @throws ApiError
   */
  public static checkOwnership(
    scopeId: string,
    msisdn: string,
    organisationNumber?: string,
    swedishPersonNumber?: string
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/public/scope/{scopeId}/number-migration/owner/msisdn/{msisdn}",
      path: {
        scopeId: scopeId,
        msisdn: msisdn,
      },
      query: {
        organisationNumber: organisationNumber,
        swedishPersonNumber: swedishPersonNumber,
      },
      errors: {
        403: `Unauthorized request.`,
        500: `Internal server error.`,
      },
    });
  }

  /**
   * Get info about a msisdn with regards to portin and transfer
   * User must have PUI 'ORDER_MOBILE_VOICE' to access this resource
   * @param scopeId
   * @param tscid
   * @param msisdn
   * @returns NumberMigrationInfoUI Successful request.
   * @throws ApiError
   */
  public static getNumberMigrationInfo(
    scopeId: string,
    tscid: string,
    msisdn: string
  ): CancelablePromise<NumberMigrationInfoUI> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/public/scope/{scopeId}/number-migration/organizations/{tscid}/msisdn/{msisdn}",
      path: {
        scopeId: scopeId,
        tscid: tscid,
        msisdn: msisdn,
      },
      errors: {
        403: `Unauthorized request.`,
        500: `Internal server error.`,
      },
    });
  }
}
