/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAlarmConfigurationRequestUI } from '../models/CreateAlarmConfigurationRequestUI';
import type { ResponseWrapperUIAlarmEventUI } from '../models/ResponseWrapperUIAlarmEventUI';
import type { ResponseWrapperUIAlarmUI } from '../models/ResponseWrapperUIAlarmUI';
import type { ResponseWrapperUIGetAlarmConfigurationsResponseUI } from '../models/ResponseWrapperUIGetAlarmConfigurationsResponseUI';
import type { ServiceUsageByAgreementUI } from '../models/ServiceUsageByAgreementUI';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlarmsControllerService {

    /**
     * @param scopeId
     * @param agreementNumber
     * @param c2Buid
     * @param agreementOnly
     * @returns ResponseWrapperUIGetAlarmConfigurationsResponseUI Successful request
     * @throws ApiError
     */
    public static getConfigurations(
        scopeId: string,
        agreementNumber?: string,
        c2Buid?: string,
        agreementOnly?: boolean,
    ): CancelablePromise<ResponseWrapperUIGetAlarmConfigurationsResponseUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-alarms/configurations',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'agreementNumber': agreementNumber,
                'c2buid': c2Buid,
                'agreementOnly': agreementOnly,
            },
        });
    }

    /**
     * @param scopeId
     * @param requestBody
     * @returns any Successful request
     * @throws ApiError
     */
    public static createConfiguration(
        scopeId: string,
        requestBody: CreateAlarmConfigurationRequestUI,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scope/{scopeId}/mobile-alarms/configurations',
            path: {
                'scopeId': scopeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scopeId
     * @param startDate
     * @param endDate
     * @param agreementNumber
     * @param c2Buid
     * @returns ResponseWrapperUIAlarmUI Successful request
     * @throws ApiError
     */
    public static getAlarms(
        scopeId: string,
        startDate: string,
        endDate: string,
        agreementNumber?: string,
        c2Buid?: string,
    ): CancelablePromise<ResponseWrapperUIAlarmUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-alarms',
            path: {
                'scopeId': scopeId,
            },
            query: {
                'agreementNumber': agreementNumber,
                'c2buid': c2Buid,
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * @param scopeId
     * @param alarmId
     * @param trafficType
     * @param refKey
     * @param refKeyDesc
     * @returns ResponseWrapperUIAlarmEventUI Successful request
     * @throws ApiError
     */
    public static getAlarmEvents(
        scopeId: string,
        alarmId: number,
        trafficType: string,
        refKey: string,
        refKeyDesc: string,
    ): CancelablePromise<ResponseWrapperUIAlarmEventUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-alarms/{alarmId}',
            path: {
                'scopeId': scopeId,
                'alarmId': alarmId,
            },
            query: {
                'trafficType': trafficType,
                'refKey': refKey,
                'refKeyDesc': refKeyDesc,
            },
        });
    }

    /**
     * @param scopeId
     * @param agreementNumber
     * @param startDate
     * @returns ServiceUsageByAgreementUI Successful request
     * @throws ApiError
     */
    public static getServiceUsageByAgreement(
        scopeId: string,
        agreementNumber: string,
        startDate: string,
    ): CancelablePromise<ServiceUsageByAgreementUI> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scope/{scopeId}/mobile-alarms/usage/agreements/{agreementNumber}',
            path: {
                'scopeId': scopeId,
                'agreementNumber': agreementNumber,
            },
            query: {
                'startDate': startDate,
            },
        });
    }

}