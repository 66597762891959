/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisturbanceServiceResponse } from '../models/DisturbanceServiceResponse';
import type { DisturbancesResponse } from '../models/DisturbancesResponse';
import type { RelatedCompaniesResult } from '../models/RelatedCompaniesResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DisturbanceControllerService {

    /**
     * Get disturbance that reported to the organization for the loggedin customer
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns DisturbanceServiceResponse OK
     * @throws ApiError
     */
    public static getDisturbanceServices(
        xAuthScopeId: string,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<DisturbanceServiceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbances/services',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get disturbance that reported to the organization for the loggedin customer
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns DisturbanceServiceResponse OK
     * @throws ApiError
     */
    public static getDisturbanceServices1(
        xAuthScopeId: string,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<DisturbanceServiceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbance/services',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Related companies that reported to the organization for the loggedin customer.
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns RelatedCompaniesResult OK
     * @throws ApiError
     */
    public static getRelatedCompanies(
        xAuthScopeId: any,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<RelatedCompaniesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbances/relatedcompanies',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Related companies that reported to the organization for the loggedin customer.
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns RelatedCompaniesResult OK
     * @throws ApiError
     */
    public static getRelatedCompanies1(
        xAuthScopeId: any,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<RelatedCompaniesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbance/relatedcompanies',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Related companies that reported to the organization for the loggedin customer.
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns RelatedCompaniesResult OK
     * @throws ApiError
     */
    public static getRelatedCompanies2(
        xAuthScopeId: any,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<RelatedCompaniesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbance/related-companies',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Related companies that reported to the organization for the loggedin customer.
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns RelatedCompaniesResult OK
     * @throws ApiError
     */
    public static getRelatedCompanies3(
        xAuthScopeId: any,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<RelatedCompaniesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbances/related-companies',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get disturbance that reported to the organization for the loggedin customer
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns DisturbancesResponse OK
     * @throws ApiError
     */
    public static getDisturbance(
        xAuthScopeId: any,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<DisturbancesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbances',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get disturbance that reported to the organization for the loggedin customer
     * @param xAuthScopeId
     * @param acceptLanguage
     * @param organizationNumber
     * @param tscId
     * @returns DisturbancesResponse OK
     * @throws ApiError
     */
    public static getDisturbance1(
        xAuthScopeId: any,
        acceptLanguage?: any,
        organizationNumber?: string,
        tscId?: string,
    ): CancelablePromise<DisturbancesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/disturbance',
            headers: {
                'X-Auth-Scope-Id': xAuthScopeId,
                'Accept-Language': acceptLanguage,
            },
            query: {
                'organizationNumber': organizationNumber,
                'tscId': tscId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                410: `Gone`,
                500: `Internal Server Error`,
            },
        });
    }

}